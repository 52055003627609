import React, { useRef } from 'react'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import ItemAction from './ItemAction'
import { useStoreActions } from 'easy-peasy';

const ItemActionCopyPageLive = ({ title, shortTitle, onClick, onAfterClick, variant, hasTooltip, IconComponent, item }) => {
    const setSnackbar = useStoreActions((actions) => actions.application.setSnackbar);
    const componentRef = useRef(null);

    if (!item) {
        return null;
    }

    const options = {
        title: title || 'Copy Live URL',
        shortTitle: shortTitle || 'Live URL',
        disabled: !item.live_url,
        IconComponent: IconComponent || LinkOutlinedIcon,
        variant: variant || "IconButton",
        hasTooltip: !!hasTooltip
    }

    const handleClick = (e) => {
        if (!!onClick) {
            onClick()
        } else {
            componentRef.current.select();
            document.execCommand('copy');
            setSnackbar({ text: 'Copied!' })
        }

        if (!!onAfterClick) {
            onAfterClick();
        }
    }

    return (
        <>
            <ItemAction onClick={handleClick} {...options} />
            {!!item.live_url && (
                <textarea
                    value={item.live_url}
                    readOnly
                    ref={componentRef}
                    style={{
                        position: 'absolute',
                        opacity: 0,
                        width: 0,
                        height: 0
                    }}
                />
            )}
        </>
    )
}

export default ItemActionCopyPageLive
