import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useStoreState, useStoreActions } from 'easy-peasy';

const CustomSnackbar = () => {
    const snackbar = useStoreState((state) => state.application.snackbar);
    const setSnackbar = useStoreActions((actions) => actions.application.setSnackbar);

    const handleSnackbarClose = () => {
        setSnackbar(false)
    }

    return (
        <Snackbar
            anchorOrigin={snackbar.origin || {
                vertical: 'top',
                horizontal: 'center',
            }}
            open={!!snackbar}
            autoHideDuration={snackbar.duration || 3000}
            onClose={handleSnackbarClose}
        >
            <Alert onClose={handleSnackbarClose} severity={snackbar.type}>
                {snackbar.text}
            </Alert>
        </Snackbar>
    )
}

export default CustomSnackbar
