import React, { useState } from 'react'
import { Fade, Box, Card, makeStyles } from '@material-ui/core'
import ItemActions from './ItemActions'
import ItemHeader from './ItemHeader'
import ItemMedia from './ItemMedia'
import ItemCategory from './ItemCategory'
import { useStoreState, useStoreActions } from 'easy-peasy';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
        transition: 'none',
        overflow: 'visible'
    },
    cardSelected: {
        position: 'relative',
        border: '1px solid #2569E8',
        boxShadow: '0px 0px 0px 1px #2569E8',
        transition: 'none',
        overflow: 'visible'
    },
    hoverActions: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        padding: '16px',
    }
}));

const ItemCard = ({ item }) => {
    const application = useStoreState((state) => state.application);
    const { selectedItem } = application
    const selectItem = useStoreActions((actions) => actions.application.selectItem);
    const classes = useStyles();
    const [showActions, setShowActions] = useState(false);

    const handleCardMouseEnter = () => {
        setShowActions(true);
    }

    const handleCardMouseLeave = () => {
        setShowActions(false);
    }

    const handleCardHoverBoxClick = () => {
        selectItem(item)
    }

    return (
        <Card className={!!selectedItem && selectedItem.id === item.id ? classes.cardSelected : classes.card} onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>

            <Fade in={showActions}>
                <Box position="absolute" zIndex="2" top={0} left={0} right={0} bottom={77} className={classes.hoverActions} display="flex" flexDirection="column" justifyContent="space-between" onClick={handleCardHoverBoxClick}>
                    <Box ml="auto">
                        <ItemActions item={item} variant="menu" />
                    </Box>
                    <Box>
                        <ItemCategory item={item} />
                    </Box>
                </Box>
            </Fade>

            <ItemMedia item={item} clickable />

            <ItemHeader item={item} clickable />

        </Card>
    )
}

export default ItemCard
