import React from 'react'
import { Typography, Box, CardHeader, Avatar, makeStyles } from '@material-ui/core'
import clsx from 'clsx';
import pageIconimage from '../../assets/media/icons/page-icon.png'
import templateIconimage from '../../assets/media/icons/template-icon.png'
import moduleIconimage from '../../assets/media/icons/module-icon.png'
import themeIconimage from '../../assets/media/icons/theme-icon.png'
import ItemHighlightedField from './ItemHighlightedField'
import ItemStatus from './ItemStatus'
import { useStoreActions } from 'easy-peasy';

const asset_types = {
    page: {
        icon: pageIconimage
    },
    template: {
        icon: templateIconimage
    },
    module: {
        icon: moduleIconimage
    },
    theme: {
        icon: themeIconimage
    }
}


const ItemHeader = ({ item, clickable, large }) => {
    const selectItem = useStoreActions((actions) => actions.application.selectItem);
    const classes = useStyles();
    const { state } = item;

    const handleItemClick = (e) => {
        e.preventDefault();
        if (!!clickable) {
            selectItem(item)
        }
    }

    return (
        <CardHeader
            onClick={handleItemClick}
            className={clsx({
                [classes.cardLarge]: !!large,
                [classes.cardClickable]: !!clickable
            })}
            avatar={
                <Box position="relative">
                    {!large && (<ItemStatus item={item} />)}

                    <Avatar
                        className={clsx([classes.avatar], {
                            [classes.avatarLarge]: !!large
                        })}
                        src={asset_types[item.asset_type].icon}
                    />
                </Box>
            }
            title={
                <Box className={!!large ? classes.titleLarge : classes.title}>
                    <Typography variant={!!large ? 'h6' : 'subtitle2'}>
                        <ItemHighlightedField item={item} field={"name"} />
                    </Typography>
                </Box>
            }
            subheader={
                <Box className={classes.subheader}>
                    <Typography variant={!!large ? 'body1' : 'caption'} title={`${!!item.theme_name ? item.theme_name + (!!item.template_name ? " - " : "") : ""}${!!item.template_name ? item.template_name : ''}`}>
                        {!!item.theme_name && (
                            <>
                                <ItemHighlightedField item={item} field={"theme_name"} />
                                {!!item.template_name && " - "}
                            </>
                        )}
                        {!!item.template_name && (
                            <ItemHighlightedField item={item} field={"template_name"} />
                        )}
                    </Typography>
                </Box>
            }
        />
    )
}

const useStyles = makeStyles((theme) => ({
    cardClickable: {
        cursor: 'pointer'
    },
    cardLarge: {
        padding: '0px 32px 24px'
    },
    avatar: {
        position: 'relative',
        width: '40px',
        height: '40px',
        border: 'none',
        color: '#959CAE'
    },
    avatarLarge: {
        position: 'relative',
        width: '60px',
        height: '60px',
    },
    title: {
        whiteSpace: 'nowrap',
        "& p": {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 500
        },
        "& em": {
            fontStyle: 'normal',
            fontWeight: 'bold'
        }
    },
    titleLarge: {
        whiteSpace: 'nowrap',
        "& h6": {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 500
        },
        "& em": {
            fontStyle: 'normal',
            fontWeight: 'bold'
        }
    },
    subheader: {
        whiteSpace: 'nowrap',
        "& p": {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        "& span": {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
        "& em": {
            fontStyle: 'normal',
            fontWeight: 'bold',
        }
    }
}));

export default ItemHeader
