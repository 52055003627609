import { createStore } from 'easy-peasy';
import { application } from './application'
import { user } from './user'
import { assets } from './assets'
import { lists } from './lists'

const BRIX_API_URL = 'https://brix-wam-api.herokuapp.com/api/'
const AUTHORIZE_ENDPOINT = BRIX_API_URL + 'authorize'

const store = createStore({
    application,
    user,
    assets,
    lists
})

export { store, AUTHORIZE_ENDPOINT }