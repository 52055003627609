import React, { useState } from 'react'
import { Box, makeStyles, Dialog, DialogTitle, DialogActions, DialogContent, Button, ButtonGroup, Typography, TextField } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import { useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    }
}))

const ListCreateDialog = ({ initialValues, open, onClose }) => {
    const classes = useStyles();
    const createList = useStoreActions((actions) => actions.lists.create);
    const [listName, setListName] = useState("")
    const [listVisibility, setListVisibility] = useState("private")
    const [error, setError] = useState("")
    const history = useHistory();

    const handleClose = () => {
        onClose()
        setListName("");
        setListVisibility("private")
        setError("")
    };

    const handleSave = async () => {
        if (!!listName) {
            setError("")
            const res = await createList({ name: listName, visibility: listVisibility, items: !!initialValues && !!initialValues.items ? initialValues.items : [] })
            setListName("");
            handleClose();
            if (!!res && !!res.slug) {
                history.push('/lists/' + res.slug)
            }
        } else {
            setError("List title is required")
        }

    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle style={{ textAlign: 'center' }}>Create new list</DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <Box mb="24px">
                    <TextField
                        type="text"
                        fullWidth
                        id="list-name"
                        label="Title"
                        variant="filled"

                        InputProps={{
                            value: listName,
                            onChange: e => {
                                if (!!e.target.value) {
                                    setError("")
                                } else {
                                    setError("List title is required")
                                }
                                setListName(e.target.value)
                            },
                            style: { backgroundColor: '#F4F7FC' }
                        }}
                        error={!!error}
                        helperText={!!error ? error : ''}
                    />
                </Box>
                <Typography variant="subtitle1" gutterBottom>Visibility</Typography>
                <ButtonGroup variant="contained" color="primary">
                    <Button
                        color={listVisibility === 'public' ? 'primary' : 'default'}
                        startIcon={<LanguageOutlinedIcon />}
                        onClick={() => {
                            setListVisibility("public")
                        }}
                    >Public</Button>
                    <Button
                        color={listVisibility === 'private' ? 'primary' : 'default'}
                        startIcon={<LockOutlinedIcon />}
                        onClick={() => {
                            setListVisibility("private")
                        }}
                    >Private</Button>
                </ButtonGroup>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button variant="outlined" onClick={handleClose} color="primary">Cancel</Button>
                <Button autoFocus onClick={handleSave} variant="contained" color="primary">Create</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ListCreateDialog
