import React from 'react';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
    palette: {
        action: {
            hover: "#F3FAFE"
        },
        primary: {
            main: '#2569E8',
            dark: '#0040b5',
            light: '#6e96ff'
        }
    },
});

theme.overrides = {
    MuiButtonGroup: {
        contained: {
            boxShadow: 'none',
            "&:hover": {
                boxShadow: 'none',
            }
        },
    },
    MuiButton: {
        root: {
            borderRadius: 5,
            textTransform: 'none',
        },
        contained: {
            backgroundColor: '#F4F7FC',
            boxShadow: 'none',
            "&:hover": {
                boxShadow: 'none',
            }
        },
        containedPrimary: {
            boxShadow: 'none',
            "&:hover": {
                boxShadow: 'none',
            }
        },

    },
    MuiDialog: {
        paper: {
            padding: "24px",
            boxSizing: 'border-box',
            width: '424px',
            maxWidth: '100%'
        }
    },
    MuiDialogTitle: {
        root: {
            padding: '24px 24px 12px'
        }
    },
    MuiDialogContent: {
        root: {
            padding: '12px 24px 24px'
        }
    },
    MuiDialogActions: {
        root: {
            padding: '24px 24px 24px'
        }
    },
    MuiListItemIcon: {
        root: {
            minWidth: '32px'
        }
    }
}


const ThemeProvider = ({ children }) => (
    <MaterialThemeProvider theme={theme}>
        {children}
    </MaterialThemeProvider>
)

export default ThemeProvider;