import React from 'react'
import { Button, Box, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom';
import emptyListImage from '../../assets/media/svg/emptyListImage.svg';

const ListEmptyPlaceholder = () => (
    <Box>
        <Box py={9} px={3} mx="auto" my={0} textAlign="center" maxWidth="433px">
            <Typography
                variant="h5"
                component="h1"
            >
                Add items to this List
            </Typography>
            <img
                src={emptyListImage}
                alt="Add items to this List"
                style={{ maxWidth: '367px', width: '100%', height: 'auto' }}
            />
            <Box mb={3}>
                <Typography>Search for pages, sections and modules, click on the List icon in the preview panel and add it to a List.</Typography>
            </Box>
            <Button component={Link} to="/" variant="contained" color="primary">Start a search</Button>
        </Box>
    </Box>
)

export default ListEmptyPlaceholder
