import React, { useState } from 'react'
import { makeStyles, Link, Grow, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Avatar, IconButton } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useStoreState } from 'easy-peasy';
import CustomTooltip from '../CustomTooltip';

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.text.primary
    }
}));

const SupportButton = () => {
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const userMenuOpened = Boolean(userMenuAnchor);
    const classes = useStyles();
    const user = useStoreState((state) => state.user);
    const { isAuthenticated } = user;

    const handleAvatarClick = (event) => {
        setUserMenuAnchor(event.currentTarget)
    }

    const handleUserMenuClose = () => {
        setUserMenuAnchor(null);
    };

    const handleListClick = () => {
        handleUserMenuClose()
        //history.push(`/lists/${list.slug}`)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setUserMenuAnchor(null);
        }
    }

    return (
        <>

            <CustomTooltip title="Support">
                <IconButton aria-controls="support-menu" aria-haspopup="true" onClick={handleAvatarClick}>
                    <HelpOutlineIcon />
                </IconButton>
            </CustomTooltip>


            <Popper open={userMenuOpened} anchorEl={userMenuAnchor} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper style={{ minWidth: 160, maxHeight: 350, overflow: 'auto' }}>
                            <ClickAwayListener onClickAway={handleUserMenuClose}>
                                <MenuList autoFocusItem={!!userMenuOpened} id="support" onKeyDown={handleListKeyDown}>
                                    <MenuItem
                                        className={classes.link}
                                        component={Link}
                                        href="https://join.slack.com/t/brixframework/shared_invite/zt-lvovbnqt-672KXxdHAIgHFPWmBvU9zQ"
                                        target="_blank"
                                    >Connect with Slack</MenuItem>
                                    <MenuItem
                                        className={classes.link}
                                        component={Link}
                                        href="https://support.inboundlabs.co/en/the-brix-framework"
                                        target="_blank"
                                    >Documentation</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </>
    )
}

export default SupportButton
