import React from 'react'
import { Box, Typography } from '@material-ui/core'
import welcomeImage from '../../assets/media/svg/welcomeImage.svg';
import AuthorizationButton from '../Authorization/AuthorizationButton'

const Welcome = () => (
    <Box>
        <Box py={9} px={3} mx="auto" my={0} textAlign="center" maxWidth="512px">
            <Typography
                variant="h5"
                component="h1"
            >
                Welcome to BRiX
            </Typography>
            <img
                src={welcomeImage}
                alt="Welcome to BRiX"
                style={{ maxWidth: '100%', width: '100%', height: 'auto' }}
            />
            <Box mb={3}>
                <Typography >The app allows you to quickly find pages, sections and modules of your existing website and reuse them. </Typography>
            </Box>
            <AuthorizationButton />
        </Box>
    </Box>
)

export default Welcome
