import React from 'react'
import Moment from 'react-moment';
import { Chip, Link, Box, makeStyles, Typography } from '@material-ui/core'
import ItemActions from './ItemActions'
import ItemHeader from './ItemHeader'
import ItemMedia from './ItemMedia'
import ItemHighlightedField from './ItemHighlightedField'
import ItemCategory from './ItemCategory'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '32px 0',
        position: 'relative'
    },
    field: {
        margin: '0 32px 24px'
    },
    caption: {
        color: 'var(--brix-color-text-default)',
        opacity: '0.6'
    },
    fieldValue: {
        color: 'var(--brix-color-text-default)',
        "& em": {
            fontStyle: 'normal',
            fontWeight: 'bold'
        }
    }
}));

const ItemDetails = ({ item }) => {
    const classes = useStyles();

    const renderField = (fieldname, label, type) => !!item[fieldname] ? (
        <div className={classes.field}>
            <Typography
                className={classes.caption}
                variant="caption">
                {label}
            </Typography>
            <Typography
                style={{ overflowWrap: fieldname === 'url' ? 'break-word' : 'normal' }}
                variant="subtitle1"
                className={classes.fieldValue}
            >
                {!!type && type === 'date' ?
                    <>
                        <Moment format="DD. MMMM YYYY HH:mm">{item[fieldname]}</Moment>
                        {fieldname === 'created_at' && !!item.author && (
                            <>{" by "}<ItemHighlightedField item={item} field={"author"} /></>
                        )}
                    </>
                    : null}
                {!!type && type === 'link' ? <Link href={item[fieldname]} target="_blank"><ItemHighlightedField item={item} field={fieldname} /></Link> : null}
                {!type && <ItemHighlightedField item={item} field={fieldname} />}
            </Typography>
        </div>
    ) : null

    const renderActions = () => (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="nowrap"
            mx="32px"
            mb="24px"
        >
            <ItemActions item={item} variant="list" />
        </Box>
    )

    const renderCategory = () => !!item.category ? (
        <Box mx="32px" mb="24px">
            <ItemCategory item={item} variant="light" />
        </Box>
    ) : null

    const renderURLField = () => !!item.url ? (
        <div className={classes.field}>
            <Typography
                className={classes.caption}
                variant="caption">
                URL
            </Typography>
            <Typography
                style={{ overflowWrap: 'break-word' }}
                variant="subtitle1"
                className={classes.fieldValue}
            >
                <Link href={item.url} target="_blank">
                    {item.url.includes('https:') ? 'https://' : 'http://'}<ItemHighlightedField item={item} field='domain' />
                    {"/"}
                    <ItemHighlightedField item={item} field='slug' />
                </Link>
            </Typography>
        </div>
    ) : null

    return (
        <div className={classes.root}>
            <ItemHeader item={item} large />
            <ItemMedia item={item} />
            {renderActions()}
            {renderCategory()}
            {renderField('updated_at', 'Last updated', 'date')}
            {renderField('published_at', 'Publish date', 'date')}
            {renderField('created_at', 'Created', 'date')}
            {renderURLField()}
        </div>
    )
}

export default ItemDetails
