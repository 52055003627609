import React, { useRef, useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy';

const Authorization = () => {
    const [loading, setLoading] = useState(true);
    const getToken = useStoreActions((actions) => actions.user.getToken);
    const getTokenRef = useRef(getToken)
    getTokenRef.current = getToken

    useEffect(() => {
        const sendCode = async () => {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let code = params.get('code');
            if (!code) {
                setLoading(false)
            } else {
                try {
                    await getTokenRef.current(code);
                } catch (err) {
                    // 
                }
            }
            setLoading(false)
        }
        sendCode();

        return () => {
            setLoading(false)
        }
    }, [])

    return !!loading ? (
        <Box>
            <Box py={9} px={3} mx="auto" my={0} textAlign="center" maxWidth="512px">
                <Box paddingBottom={4}>
                    <CircularProgress />
                </Box>
                <Typography variant="subtitle1">Authorization...</Typography>
            </Box>
        </Box>
    ) : (<Redirect to="/" />)
}

export default Authorization
