import React from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import ItemHighlightedField from './ItemHighlightedField'

const useStyles = makeStyles((theme) => ({
    categoryChipPrimary: {
        textTransform: 'capitalize',
        backgroundColor: '#2569E8',
        color: '#fff',
        "& em": {
            fontStyle: 'normal',
            fontWeight: 'bold'
        }
    },
    categoryChipLight: {
        textTransform: 'capitalize',
        backgroundColor: 'rgb(236, 243, 247)',
        color: theme.palette.text.primary,
        "& em": {
            fontStyle: 'normal',
            fontWeight: 'bold'
        }
    }
}));

const ItemCategory = ({ item, variant = 'primary' }) => {
    const classes = useStyles();

    return null;
    /*return !!item.category ? (
        <Chip
            className={variant === 'light' ? classes.categoryChipLight : classes.categoryChipPrimary}
            label={
                <ItemHighlightedField
                    item={item}
                    field="category"
                    transformValue={
                        (value) => value.split('_').join(' ')
                    } />
            }
        />
    ) : null*/

}

export default ItemCategory
