import React from 'react'
import { IconButton, makeStyles, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ItemsHeaderSelectableActions from './ItemsHeaderSelectableActions'

const useStyles = makeStyles((theme) => ({
    header: {
        position: 'sticky',
        background: theme.palette.background.paper,
        top: '64px',
        zIndex: 3
    },
    headerTop: {
        padding: '16px 20px 8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
    },
    headerLeft: {
        marginRight: 20
    },
    headerRight: {
        marginLeft: 'auto',
        "& button": {
            color: theme.palette.text.primary,
            "&:hover": {
                background: theme.palette.action.hover,
                color: theme.palette.primary.main
            }
        }
    },
    icon: {
        fontSize: '20px',
    },
    iconButton: {
        padding: 8,
        backgroundColor: 'transparent',
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        }
    },
    iconButtonActive: {
        background: theme.palette.action.hover,
        color: `${theme.palette.primary.main} !important`,
        "& button": {
            color: theme.palette.primary.main,
        }
    },
    selectablePanel: {
        padding: '0px 20px 12px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    selectablePanelButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginLeft: 28,
        "& > *": {
            margin: '0 4px'
        }
    }
}));

const ItemsHeader = (props) => {
    const { total } = props
    const classes = useStyles();
    const sidebarOpened = useStoreState((state) => state.application.sidebarOpened);
    const toggleSidebar = useStoreActions((actions) => actions.application.toggleSidebar);


    return (
        <div className={classes.header}>
            <div className={classes.headerTop}>
                <div className={classes.headerLeft}>
                    <Typography variant="subtitle1" style={{ color: 'var(--brix-color-text-default)' }}>{total.pages} pages, {total.templates} templates, {total.modules} modules, {total.themes} themes</Typography>
                </div>
                <div className={classes.headerRight}>
                    <IconButton onClick={() => {
                        toggleSidebar()
                    }} className={`${classes.iconButton} ${!!sidebarOpened ? classes.iconButtonActive : ''}`}>
                        <InfoOutlinedIcon />
                    </IconButton>
                </div>
            </div>
            <ItemsHeaderSelectableActions {...props} />
        </div>
    )
}

export default ItemsHeader
