import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import CustomTooltip from '../CustomTooltip'

const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: 8,
        backgroundColor: 'var(--brix-color-icon-bg-default)',
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        color: theme.palette.text.primary
    },
    icon: {
        fontSize: '20px',
        color: 'inherit'
    },
}));


const ItemActionIconButton = ({ hasTooltip, title, onClick, disabled, IconComponent, IconButtonClassName }) => {
    const classes = useStyles();

    return !!hasTooltip ? !!disabled ? (
        <span>
            <IconButton
                className={`${classes.iconButton} ${!!IconButtonClassName ? IconButtonClassName : ''}`}
                onClick={onClick}
                disabled={disabled}
            >
                <IconComponent className={classes.icon} />
            </IconButton>
        </span>
    ) : (
            <CustomTooltip title={title}>
                <IconButton
                    className={`${classes.iconButton} ${!!IconButtonClassName ? IconButtonClassName : ''}`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    <IconComponent className={classes.icon} />
                </IconButton>
            </CustomTooltip>
        ) : <IconButton
            className={classes.iconButton}
            onClick={onClick}
            disabled={disabled}
        >
            <IconComponent className={classes.icon} />
        </IconButton>
}

export default ItemActionIconButton
