import React from 'react'
import ItemActionsList from './ItemActionsList'
import ItemActionsMenu from './ItemActionsMenu'


const ItemActions = ({ item, variant }) => {
    if (variant === 'list') {
        return <ItemActionsList item={item} />
    }

    if (variant === 'menu') {
        return <ItemActionsMenu item={item} />
    }

    return null;
}

export default ItemActions
