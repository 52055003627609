import React, { useState } from 'react'
import { Grow, Popper, Paper, ClickAwayListener, MenuList, makeStyles, IconButton } from '@material-ui/core'
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import { useHistory, useLocation } from 'react-router-dom'
import { useStoreState } from 'easy-peasy';
import ListItem from '../Lists/ListItem'
import CustomTooltip from '../CustomTooltip';

const useStyles = makeStyles((theme) => ({
    active: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.primary.main
    }
}))

const ListsButton = () => {
    const location = useLocation();
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const userMenuOpened = Boolean(userMenuAnchor);
    const user = useStoreState((state) => state.user);
    const { isAuthenticated } = user;
    const history = useHistory();
    const lists = useStoreState((state) => state.lists.lists);
    const classes = useStyles();

    const handleAvatarClick = (event) => {
        setUserMenuAnchor(event.currentTarget)
    }

    const handleUserMenuClose = () => {
        setUserMenuAnchor(null);
    };

    const handleListClick = () => {
        handleUserMenuClose()
        //history.push(`/lists/${list.slug}`)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setUserMenuAnchor(null);
        }
    }

    console.log('location.pathname', location.pathname);

    return !!isAuthenticated ? (
        <>
            <CustomTooltip title="My lists">
                <IconButton
                    aria-controls="my-lists-menu"
                    aria-haspopup="true"
                    onClick={() => {
                        const firstList = !!lists && !!lists.items ? lists.items[0] : false;
                        if (!!firstList) {
                            history.push('/lists/' + firstList.slug)
                        } else {
                            history.push('/lists')
                        }
                    }}
                    className={!!location.pathname && location.pathname.includes('/lists') ? classes.active : ''}
                >
                    <BookmarkBorderOutlinedIcon />
                </IconButton>
            </CustomTooltip>

            <Popper open={userMenuOpened} anchorEl={userMenuAnchor} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper style={{ minWidth: 160, maxHeight: 350, overflow: 'auto' }}>
                            <ClickAwayListener onClickAway={handleUserMenuClose}>
                                <MenuList autoFocusItem={!!userMenuOpened} id="my-lists-menu" onKeyDown={handleListKeyDown}>
                                    {!!lists && !!lists.items && lists.items.map(item => (
                                        <ListItem onAfterClick={handleListClick} key={item.id} list={item} />
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </>
    ) : null
}

export default ListsButton
