
import React, { useRef, useEffect } from 'react'
import ItemsList from '../Items/ItemsList'
import { useStoreState, useStoreActions } from 'easy-peasy';

const ItemsAll = () => {
    const allItems = useStoreState((state) => state.assets.all);
    const total = useStoreState((state) => state.assets.total);
    const getAssets = useStoreActions((actions) => actions.assets.getAssets);
    const loadMoreAssets = useStoreActions((actions) => actions.assets.loadMoreAssets);
    const getAssetsRef = useRef(getAssets)
    getAssetsRef.current = getAssets

    useEffect(() => {
        getAssetsRef.current()
    }, [])

    return (
        <ItemsList
            items={allItems}
            loadMore={loadMoreAssets}
            hasMore={allItems.length < total.all}
            total={total}
        />
    )
}

export default ItemsAll