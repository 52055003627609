import React, { useRef, useEffect } from 'react'
import { makeStyles, Toolbar, Drawer } from '@material-ui/core'
import Lists from '../Lists'
import Assets from '../Assets'
import Sidebar from '../Sidebar'
import Snackbar from '../Snackbar';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ListsSidebar from '../Lists/ListsSidebar'
import { Route } from 'react-router-dom'

const drawerWidth = 368;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    drawer: {
        flexShrink: 0,
    },
    leftDrawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
        overscrollBehavior: 'contain',
        flex: 1
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: '0',
        marginLeft: 0,
        width: '100%'
    },
    contentShift: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
        marginLeft: 0,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        },
        width: '100%'
    },
    contentShiftLeft: {
        width: '100%',
        marginLeft: drawerWidth,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }
}));

const Layout = ({ page }) => {
    const application = useStoreState((state) => state.application);
    const { sidebarOpened, leftSidebarOpened } = application;
    const openLeftSidebar = useStoreActions((actions) => actions.application.openLeftSidebar);
    const closeLeftSidebar = useStoreActions((actions) => actions.application.closeLeftSidebar);
    const getLists = useStoreActions((actions) => actions.lists.getLists);

    const classes = useStyles();
    const getListsRef = useRef(getLists);
    getListsRef.current = getLists;
    const openLeftSidebarRef = useRef(openLeftSidebar);
    openLeftSidebarRef.current = openLeftSidebar;
    const closeLeftSidebarRef = useRef(closeLeftSidebar);
    closeLeftSidebarRef.current = closeLeftSidebar;

    useEffect(() => {
        getListsRef.current();
        if (!page || page !== 'all') {
            openLeftSidebarRef.current();
        } else {
            closeLeftSidebarRef.current();
        }
    }, [page])

    return (
        <div className={classes.root}>

            <Route path="/lists">
                <Drawer className={classes.leftDrawer}
                    variant="persistent"
                    open={leftSidebarOpened}
                    anchor="left"
                    classes={{
                        paper: classes.drawerPaper,
                    }}>
                    <Toolbar />
                    <div className={classes.drawerContainer}>
                        <ListsSidebar />
                    </div>
                </Drawer>
                <div className={`${!!sidebarOpened ? classes.contentShift : classes.content} ${classes.contentShiftLeft}`}>
                    <Lists />
                </div>
            </Route>

            <Route path="/" exact>
                <div className={`${!!sidebarOpened ? classes.contentShift : classes.content}`}>
                    <Assets />
                </div>
            </Route>

            <Drawer className={classes.drawer}
                variant="persistent"
                open={sidebarOpened}
                anchor="right"
                classes={{
                    paper: classes.drawerPaper,
                }}>
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <Sidebar />
                </div>
            </Drawer>

            <Snackbar />
        </div>
    )
}

export default Layout
