import React, { useState } from 'react'
import { makeStyles, Box, Button, CircularProgress, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useStoreActions, useStoreState } from 'easy-peasy';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useRouteMatch } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiFolderMoveOutline } from '@mdi/js';
import ListCreateButton from '../Lists/ListCreateButton'
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
}))

const ItemsHeaderSelectableActions = ({ total, selectable, changeMode, selectedItems, selectMode }) => {
    const classes = useStyles()
    const removeFromList = useStoreActions((actions) => actions.lists.removeFromList);
    const moveToList = useStoreActions((actions) => actions.lists.moveToList);
    const lists = useStoreState((state) => state.lists.lists);
    const match = useRouteMatch("/lists/:slug");
    const slug = !!match && !!match.params ? match.params.slug : false;
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [moveLoading, setMoveLoading] = useState(false)
    const [menu, setMenu] = useState(false)
    const menuOpened = Boolean(menu);

    const handleSelectClick = (e) => {
        changeMode()
    }

    const handleDeleteClick = async (e) => {
        setDeleteLoading(true)
        try {
            await removeFromList({ items: selectedItems, list: { id: slug } })
            setDeleteLoading(false)
        } catch (err) {
            //
            setDeleteLoading(false)
        }
    }

    const handleMoveClick = (e) => {
        setMenu(e.currentTarget)
    }

    const handleMoveToListItemClick = async (list) => {
        setMenu(false)
        setMoveLoading(true)
        try {
            await moveToList({ list, items: selectedItems, from: { id: slug } })
        } catch (err) {
            //
        }
        setMoveLoading(false)
    }

    return !!selectable && !!total.all ? (
        <Box
            display="flex"
            pt="0px"
            pb="12px"
            px="20px"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
        >
            {!!selectMode ? (
                <Button
                    onClick={handleSelectClick}
                    startIcon={<CloseIcon />}
                    color="primary"
                    variant="outlined"
                >{selectedItems.length} Selected</Button>
            ) : (
                    <Button
                        onClick={handleSelectClick}
                        color="primary"
                        variant="outlined"
                    >Select</Button>
                )}
            {!!selectMode && (
                <Box
                    display="flex"
                    ml="28px"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                >
                    <Box mx="4px">
                        <Button
                            disabled={!selectedItems.length}
                            onClick={handleDeleteClick}
                            startIcon={!!deleteLoading ?
                                <CircularProgress size={16} /> :
                                <DeleteOutlineIcon />
                            }
                            color="primary"
                            variant="outlined"
                        >Delete</Button>
                    </Box>
                    <Box mx="4px">
                        <Button
                            disabled={!selectedItems.length}
                            startIcon={!!moveLoading ?
                                <CircularProgress size={16} /> :
                                <Icon size={0.85} path={mdiFolderMoveOutline} />
                            }
                            color="primary"
                            variant="outlined"
                            onClick={handleMoveClick}
                            aria-controls={menuOpened ? 'add-to-list-menu' : undefined} aria-haspopup="true"
                        >Move to</Button>
                        <Popper open={menuOpened} anchorEl={menu} role={undefined} transition className={classes.popper}>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper style={{ maxHeight: 300, minWidth: 250, overflow: 'auto', overscrollBehavior: 'none' }}>
                                        <ClickAwayListener onClickAway={() => {
                                            setMenu(null)
                                        }}>
                                            <div>
                                                <MenuList role={undefined} id="add-to-list-menu">
                                                    {!!lists && !!lists.items && lists.items.length > 0 && lists.items.filter(l => l.slug !== slug).map((listItem, i) => (
                                                        <MenuItem
                                                            key={listItem.id}
                                                            onClick={() => { handleMoveToListItemClick(listItem) }}
                                                        >
                                                            <ListItemIcon>
                                                                <BookmarkOutlinedIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <ListItemText>{listItem.name}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                                <Box mx={2} mt={0} mb={2}>
                                                    <ListCreateButton
                                                        onClose={() => {
                                                            setMenu(null)
                                                        }}
                                                        fullWidth
                                                        initialValues={{
                                                            items: selectedItems
                                                        }}
                                                    />
                                                </Box>
                                            </div>

                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>
                </Box>
            )}
        </Box>
    ) : null
}

export default ItemsHeaderSelectableActions
