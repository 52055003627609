import React from 'react'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ItemAction from './ItemAction'
import { useStoreActions } from 'easy-peasy';

const ItemActionClonePage = ({ title, shortTitle, onClick, onAfterClick, variant, hasTooltip, IconComponent, IconButtonClassName, item }) => {
    const cloneAsset = useStoreActions((actions) => actions.assets.cloneAsset);

    if (!item || !item.asset_type || item.asset_type !== 'page') {
        return null;
    }

    const options = {
        title: title || 'Clone',
        shortTitle: shortTitle || 'Clone',
        disabled: false,
        IconComponent: IconComponent || FileCopyOutlinedIcon,
        variant: variant || "IconButton",
        hasTooltip: !!hasTooltip,
        IconButtonClassName: IconButtonClassName
    }

    const handleClick = (e) => {
        if (!!onClick) {
            onClick()
        } else {
            cloneAsset(item.id);
        }

        if (!!onAfterClick) {
            onAfterClick();
        }
    }

    return (
        <ItemAction onClick={handleClick} {...options} />
    )
}

export default ItemActionClonePage
