import React from 'react'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ItemAction from './ItemAction'

const ItemActionViewPagePreview = ({ title, shortTitle, onClick, onAfterClick, variant, hasTooltip, IconComponent, IconButtonClassName, item }) => {

    if (!item) {
        return null;
    }

    const options = {
        title: title || 'Preview',
        shortTitle: shortTitle || 'Preview',
        disabled: !item.preview_url,
        IconComponent: IconComponent || VisibilityOutlinedIcon,
        variant: variant || "IconButton",
        hasTooltip: !!hasTooltip,
        IconButtonClassName: IconButtonClassName
    }

    const handleClick = (e) => {
        if (!!onClick) {
            onClick()
        } else {
            var win = window.open(item.preview_url, '_blank');
            if (win) {
                win.focus();
            } else {
                alert('Please allow popups for this website');
            }
        }

        if (!!onAfterClick) {
            onAfterClick();
        }
    }

    return (
        <ItemAction onClick={handleClick} {...options} />
    )
}

export default ItemActionViewPagePreview
