import { action, actionOn } from 'easy-peasy';

const application = {
    isSearching: false,
    leftSidebarOpened: true,
    searchQuery: '',
    selectedItem: false,
    sidebarOpened: false,
    snackbar: false,
    onSetSearchQuery: actionOn(
        actions => actions.setSearchQuery,
        (state, target) => {
            state.isSearching = true
        }
    ),
    onSelectItem: actionOn(
        actions => actions.selectItem,
        (state, target) => {
            state.sidebarOpened = !!target.payload ? true : state.sidebarOpened
        }
    ),
    setSearching: action((state, payload) => {
        state.isSearching = payload
    }),
    setSearchQuery: action((state, payload) => {
        state.searchQuery = payload
    }),
    toggleSidebar: action((state, payload) => {
        state.sidebarOpened = !state.sidebarOpened
    }),
    selectItem: action((state, payload) => {
        state.selectedItem = payload || false
    }),
    setSnackbar: action((state, payload) => {
        state.snackbar = !!payload ? { ...payload } : false
    }),
    openLeftSidebar: action((state, payload) => {
        state.leftSidebarOpened = true

    }),
    closeLeftSidebar: action((state, payload) => {
        state.leftSidebarOpened = false
    })
}

export {
    application
}