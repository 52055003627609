import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'

const CustomTooltip = (props) => {
    const { children } = props;
    const tooltipClasses = useTooltipStyles()

    return <Tooltip {...props} classes={tooltipClasses}>{children}</Tooltip>
}

const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#141416',
        borderRadius: '8px',
        color: '#fff',
        fontSize: 12,
        boxSahdow: 'none',
        fontWeight: 'normal',
        padding: '2px 10px',
        lineHeight: '150%'
    },
}))

export default CustomTooltip
