import React from 'react'
import { makeStyles } from '@material-ui/core'
import CustomTooltip from '../CustomTooltip'

const ItemStatus = ({ item }) => {
    const classes = useStyles();
    return !!item && !!item.state ? (
        <div className={classes.container} style={{ backgroundColor: item.state.toLowerCase() === 'published' ? '#05CE7C' : '#D6DDE0' }}>
            <CustomTooltip title={item.state.charAt(0).toUpperCase() + item.state.slice(1).toLowerCase()}>
                <button className={classes.button} />
            </CustomTooltip>
        </div>
    ) : null
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#D6DDE0',
        border: '3px solid #fff',
        borderRadius: '50%',
        width: '8px',
        height: '8px',
        position: 'absolute',
        left: '-3px',
        bottom: '-3px',
        zIndex: 1
    },
    button: {
        position: 'absolute',
        left: '-4px',
        right: '-4px',
        top: '-4px',
        bottom: '-4px',
        opacity: 0,
        overflow: 'hidden',
        padding: 0,
        width: 16,
        height: 16,
    }
}))

export default ItemStatus
