import React from 'react'
import { makeStyles, ListItem as MaterialListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@material-ui/core'
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useStoreState } from 'easy-peasy';

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderRadius: '8px',
        marginBottom: '8px',
        "&:hover": {
            background: '#F4F7FC',
            cursor: 'pointer'
        }
    },
    listItemActive: {
        background: '#F4F7FC',
        color: theme.palette.primary.main,
        "&:hover": {
            cursor: 'default'
        }
    },
    avatar: {
        background: '#F4F7FC',
        color: theme.palette.text.primary
    },
    avatarActive: {
        color: theme.palette.primary.main,
    }
}))

const ListItem = ({ list, onAfterClick }) => {
    const match = useRouteMatch("/lists/:slug");
    const history = useHistory();
    const hub_id = useStoreState((state) => state.user.user.hub_id);
    const slug = !!match && !!match.params ? match.params.slug : false;
    const classes = useStyles();

    const length = list.items.filter(i => i.portal_id === hub_id).length

    const handleListClick = (list) => {
        if (!!list && !!list.slug) {
            history.push(`/lists/${list.slug}`);
        }
        if (!!onAfterClick) {
            onAfterClick();
        }

    }

    return (
        <MaterialListItem
            className={`${classes.listItem} ${!!slug && list.slug === slug ? classes.listItemActive : ''}`}
            onClick={() => {
                handleListClick(list)
            }}
            key={list.id}
        >
            <ListItemAvatar>
                <Avatar className={`${classes.avatar} ${!!slug && list.slug === slug ? classes.avatarActive : ''}`}>
                    <BookmarkOutlinedIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                style={{ marginTop: 0, marginBottom: 0 }}
                primary={<Typography variant="body2">{list.name}</Typography>}
                secondary={<Typography variant="caption">{`${list.visibility} • ${length} item${length === 1 ? '' : 's'}`}</Typography>}
            />
        </MaterialListItem>
    )
}

export default ListItem
