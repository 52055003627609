import React from 'react'
import WifiTetheringOutlinedIcon from '@material-ui/icons/WifiTetheringOutlined';
import ItemAction from './ItemAction'

const ItemActionViewLivePage = ({ title, shortTitle, onClick, onAfterClick, variant, hasTooltip, IconComponent, item }) => {

    if (!item || !item.live_url) {
        return null;
    }

    const options = {
        title: title || 'View Live',
        shortTitle: shortTitle || 'Live',
        disabled: !item.live_url,
        IconComponent: IconComponent || WifiTetheringOutlinedIcon,
        variant: variant || "IconButton",
        hasTooltip: !!hasTooltip
    }

    const handleClick = (e) => {
        if (!!onClick) {
            onClick()
        } else {
            let win = window.open(item.live_url, '_blank');
            if (win) {
                win.focus();
            } else {
                alert('Please allow popups for this website');
            }
        }

        if (!!onAfterClick) {
            onAfterClick();
        }
    }

    return (
        <ItemAction onClick={handleClick} {...options} />
    )
}

export default ItemActionViewLivePage
