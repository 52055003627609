import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ItemsByListSlug from '../Items/ItemsByListSlug'

const Lists = () => {

    return (
        <Switch>
            <Route path="/lists" exact>
                <Redirect to="/" />
            </Route>
            <Route path="/lists/:slug" children={<ItemsByListSlug />} />
        </Switch>
    )
}

export default Lists