import React, { useState, useEffect } from 'react'
import { Box, Grid, CircularProgress, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroller';
import ItemCard from './ItemCard'
import ItemsHeader from './ItemsHeader'
import { useStoreActions } from 'easy-peasy';
import { Route, Switch } from 'react-router-dom';
import ListEmptyPlaceholder from '../Lists/ListEmptyPlaceholder';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
    itemSelector: {
        display: 'block',
        top: 12,
        left: 12,
        bottom: 12,
        right: 12,
        background: 'rgba(6, 25, 66, 0.4)',
        position: 'absolute',
        zIndex: 2,
        borderRadius: '4px',
        cursor: 'pointer'
    },
    pickIcon: {
        position: 'absolute',
        right: 16,
        top: 16,
        zIndex: 2,
        color: '#fff',
        borderRadius: '50%',
        width: 20,
        height: 20,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    pickIconActive: {
        background: theme.palette.primary.main
    }
}))

const ItemsList = ({ items, loadMore, hasMore, total, selectable }) => {
    const classes = useStyles();
    const selectItem = useStoreActions((actions) => actions.application.selectItem);
    const [selectMode, setSelectMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const changeMode = () => {
        if (!!selectMode) {
            setSelectMode(false);
            setSelectedItems([])
        } else {
            setSelectMode(true);
        }
    }

    const resetMode = () => {
        setSelectMode(false);
        setSelectedItems([])
    }

    const pickItem = (item) => {
        if (!!selectedItems.find(i => i.id === item.id)) {
            setSelectedItems([...selectedItems.filter(i => i.id !== item.id)])
        } else {
            setSelectedItems([...selectedItems, item])
        }
    }

    const renderSelectableArea = (i) => !!selectable && !!selectMode && (
        <div className={classes.itemSelector} onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            pickItem(i)
        }}>
            <div className={`${classes.pickIcon} ${!!selectedItems.find(item => item.id === i.id) ? classes.pickIconActive : ''}`}>
                {!!selectedItems.find(item => item.id === i.id) ? (
                    <CheckCircleOutlineIcon />
                ) : (
                        <RadioButtonUncheckedIcon />
                    )}
            </div>
        </div>
    )

    const itemsToRender = []
    items.filter(i => !!i.id).map((i, n) => {
        itemsToRender.push(
            <Grid item xl={2} lg={3} md={4} sm={6} xs={6} key={`${i.id}-${n}`} style={{ position: 'relative' }}>
                <ItemCard item={i} />
                {renderSelectableArea(i)}
            </Grid>
        )
    })

    const hasParentWithClass = (element, classname) => {
        if (!!element && !!element.className && typeof element.className === 'string' && element.className.split(' ').indexOf(classname) >= 0) return true;
        return element.parentNode && hasParentWithClass(element.parentNode, classname);
    }

    const onItemsContainerClick = (event) => {
        event.preventDefault();
        if (!hasParentWithClass(event.target, 'MuiCard-root')) {
            selectItem(false)
        }
    }

    useEffect(() => {
        if (!!selectable) {
            resetMode()
        }
    }, [selectable, items])

    return (
        <div style={{ position: 'relative' }}>

            <ItemsHeader
                total={total}
                selectable={selectable}
                changeMode={changeMode}
                selectedItems={selectedItems}
                selectMode={selectMode}
            />

            {!!total && total.all < 1 && (
                <Switch>
                    <Route path="/lists/*">
                        <ListEmptyPlaceholder />
                    </Route>
                </Switch>
            )}

            <InfiniteScroll
                initialLoad={false}
                loadMore={loadMore}
                hasMore={hasMore}
                loader={
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        p={3}
                        key={0}
                    >
                        <CircularProgress />
                    </Box>
                }
            >
                <Grid
                    onClick={onItemsContainerClick}
                    container
                    spacing={3}
                    style={{ padding: '1px 8px 20px', margin: 0, width: '100%' }}
                >
                    {itemsToRender}
                </Grid>
            </InfiniteScroll>

        </div>
    )
}

export default ItemsList
