import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import Imgix, { Background } from "react-imgix";
import pagePlaceholderimage from '../../assets/media/placeholders/page-placeholder.png'
import pageProcessingPlaceholderimage from '../../assets/media/placeholders/page-processing-placeholder.png'
import templatePlaceholderimage from '../../assets/media/placeholders/template-placeholder.png'
import templateProcessingPlaceholderimage from '../../assets/media/placeholders/template-processing-placeholder.png'
import themePlaceholderimage from '../../assets/media/placeholders/theme-placeholder.png'
import themeProcessingPlaceholderimage from '../../assets/media/placeholders/theme-processing-placeholder.png'
import modulePlaceholderimage from '../../assets/media/placeholders/module-placeholder.png'
import moduleProcessingPlaceholderimage from '../../assets/media/placeholders/module-processing-placeholder.png'
import { useStoreActions } from 'easy-peasy';

const asset_types = {
    page: {
        processing: () => (
            <svg width="283" height="183" viewBox="0 0 283 183" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="283" height="183" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M165.367 67.125H115.633C112.913 67.125 110.708 69.5501 110.708 72.5417V110.458C110.708 113.45 112.913 115.875 115.633 115.875H129.667H135.083H165.367C168.087 115.875 170.292 113.45 170.292 110.458V72.5417C170.292 69.5501 168.087 67.125 165.367 67.125ZM135.083 110.458H164.875V86.0833H135.083V110.458ZM129.667 86.0833H116.125V110.458H129.667V86.0833ZM164.875 80.6667H116.125V72.5417H164.875V80.6667Z" fill="#2569E8" fillOpacity="0.5" />
                <circle cx="141" cy="92" r="59.5" stroke="#2569E8" strokeDasharray="4 8" />
            </svg>
        ),
        placeholder: pagePlaceholderimage,
        processingImage: pageProcessingPlaceholderimage,
    },
    template: {
        placeholder: templatePlaceholderimage,
        processingImage: templateProcessingPlaceholderimage
    },
    theme: {
        placeholder: themePlaceholderimage,
        processingImage: themeProcessingPlaceholderimage
    },
    module: {
        placeholder: modulePlaceholderimage,
        processingImage: moduleProcessingPlaceholderimage
    }
}

const ItemMedia = ({ item, clickable }) => {
    const selectItem = useStoreActions((actions) => actions.application.selectItem);
    const classes = useStyles();
    const { preview_image, preview_image_status } = item

    const handleItemClick = (e) => {
        e.preventDefault();
        if (!!clickable) {
            selectItem(item)
        }
    }

    const renderProcessingAnimation = () => (
        <Box className={classes.mediaLarge}>
            {asset_types.page.processing}
            <Box className={classes.mediaContent}>
                <Typography className={classes.processingCaption}>generating preview</Typography>
            </Box>
        </Box>
    )

    const renderPreviewPlacholder = () => (
        <Box
            style={{
                backgroundImage: `url(${!!preview_image_status && preview_image_status === 'processing' ? asset_types[item.asset_type].processingImage : asset_types[item.asset_type].placeholder})`,
                backgroundSize: 'cover'
            }}
            className={!!clickable ? classes.media : classes.mediaLarge}
        >
            <Box className={classes.mediaContent}>
                {!!preview_image_status && preview_image_status === 'processing' && (
                    <Typography className={classes.processingCaption}>
                        generating preview
                    </Typography>
                )}
            </Box>
        </Box>
    )

    const renderPreviewImageLarge = () => (
        <Box pt={1} pb={3}>
            <Imgix
                src={item.preview_image}
                width={368}
                maxWidth="100%"
                className={classes.preview}
            />
        </Box>
    )

    const renderPreviewImage = () => (
        <Background
            src={preview_image}
            imgixParams={{ w: 350, h: 200, crop: 'top' }}
            className={classes.media}
        >
            <Box className={classes.mediaContent}></Box>
        </Background>
    )

    return !!clickable ? (
        <Box className={classes.root} onClick={handleItemClick}>
            {!!preview_image ? renderPreviewImage() : renderPreviewPlacholder()}
        </Box>
    ) : !!item.preview_image ? (
        renderPreviewImageLarge()
    ) : !!preview_image_status && preview_image_status === 'processing' ?
                renderProcessingAnimation()
                : renderPreviewPlacholder()
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',

        textAlign: 'initial'
    },
    media: {
        cursor: 'pointer',
        backgroundPosition: 'center top',
        borderTop: '1px solid #D3DEEC',
        borderBottom: '1px solid #D3DEEC',
    },
    mediaLarge: {
        backgroundPosition: 'center top',
        marginBottom: 24,
        position: 'relative',
        "& svg": {
            position: 'absolute',
            left: 0,
            right: 0,
            top: -10,
            bottom: 0,
            width: '100%',
            height: 'auto',
            "& circle": {
                transformOrigin: 'center',
                animation: 'infinite-spinning 30s linear infinite'
            }
        }
    },
    mediaContent: {
        height: 0,
        paddingTop: '64.5%',
        position: 'relative'
    },
    processingCaption: {
        position: 'absolute',
        bottom: 10,
        left: '50%',
        transform: 'translateX(-50%)',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '12px',
        textAlign: 'center',
        letterSpacing: '0.25px',
        color: '#2569E8'
    },
    preview: {
        maxWidth: '100%',
        width: '100%',
        height: 'auto'
    },
}));

export default ItemMedia
