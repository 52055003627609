import React from 'react'
import ItemActionIconButton from './ItemActionIconButton'
import ItemActionMenuItem from './ItemActionMenuItem'

const ItemAction = (props) => {
    const { variant } = props;

    if (variant === 'IconButton') {
        return (
            <ItemActionIconButton {...props} />
        )
    }

    if (variant === 'MenuItem') {
        return (
            <ItemActionMenuItem {...props} />
        )
    }

    return null;

}

export default ItemAction
