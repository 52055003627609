import React from 'react'
import { makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    placeholder: {
        padding: '100px 30px',
        color: '#959CAE',
        textAlign: 'center',
    },
    placeholder_icon: {
        textAlign: 'center',
        color: '#959CAE'
    },
    placeholder_text: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '0.15px',
        color: '#959CAE',
        marginTop: 0
    }
}));

const Sidebar = () => {
    const classes = useStyles();

    return (
        <Box className={classes.placeholder}>
            <Box className={classes.placeholder_icon}>
                <svg width="131" height="131" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M115.617 16.375H15.3824C9.90143 16.375 5.45819 21.2626 5.45819 27.2917V103.708C5.45819 109.737 9.90143 114.625 15.3824 114.625L43.6664 114.625H54.583L115.617 114.625C121.098 114.625 125.542 109.737 125.542 103.708V27.2917C125.542 21.2626 121.098 16.375 115.617 16.375ZM54.583 103.708H114.625V54.5833H54.583V103.708ZM43.6664 54.5833H16.375V103.708H43.6664V54.5833ZM114.625 43.6667H16.375V27.2916H114.625V43.6667Z" fill="currentColor" />
                </svg>
            </Box>

            <Box className={classes.placeholder_text}>Select a page or pattern to see a preview</Box>
        </Box>
    )
}

export default Sidebar
