import React, { useState } from 'react'
import { Grow, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Avatar, IconButton, Tooltip } from '@material-ui/core'
import { useStoreState, useStoreActions } from 'easy-peasy';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CustomTooltip from '../CustomTooltip'

const SettingsButtton = () => {
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const userMenuOpened = Boolean(userMenuAnchor);
    const userState = useStoreState((state) => state.user);
    const logout = useStoreActions((actions) => actions.user.logout);
    const { isAuthenticated, user } = userState;
    const { email } = user;

    const handleAvatarClick = (event) => {
        setUserMenuAnchor(event.currentTarget)
    }

    const handleUserMenuClose = () => {
        setUserMenuAnchor(null);
    };

    const handleLogoutClick = () => {
        handleUserMenuClose()
        logout()
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setUserMenuAnchor(null);
        }
    }

    return !!isAuthenticated ? (
        <>
            <CustomTooltip title="Settings">
                <IconButton aria-controls="settings-menu" aria-haspopup="true" onClick={handleAvatarClick}>
                    <SettingsOutlinedIcon />
                </IconButton>
            </CustomTooltip>

            <Popper open={userMenuOpened} anchorEl={userMenuAnchor} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleUserMenuClose}>
                                <MenuList autoFocusItem={!!userMenuOpened} id="settings-menu" onKeyDown={handleListKeyDown}>
                                    {!!user.hub_id ? <MenuItem onClick={handleUserMenuClose}>HubSpot Portal: {user.hub_id}</MenuItem> : null}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </>
    ) : null
}

export default SettingsButtton
