import { action, thunk, computed } from 'easy-peasy';
import axios from 'axios';

const BRIX_API_URL = 'https://brix-wam-api.herokuapp.com/api/'
const VERIFY_ENDPOINT = BRIX_API_URL + 'verify'
const TOKEN_ENDPOINT = BRIX_API_URL + 'token'

let savedToken = localStorage.getItem('brixToken')

const user = {
    token: !!savedToken ? savedToken : null,
    user: {},
    isAuthenticated: computed(state => !!state.user.email),
    setUser: action((state, payload) => {
        state.user = payload
    }),
    setToken: action((state, payload) => {
        state.token = payload
    }),
    logout: thunk((actions, payload) => {
        localStorage.removeItem('brixToken')
        actions.setToken(null)
        actions.setUser({})
    }),
    login: thunk((actions, payload) => {
        actions.setUser(payload)
    }),
    verifyToken: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        if (!!state.user.token && !state.user.isAuthenticated) {
            const res = await axios.post(
                VERIFY_ENDPOINT,
                { token: state.user.token }
            ).catch(e => { console.log('verifyToken error: ', e.message); })

            if (!!res && !!res.data && !!res.data.email) {
                //console.log('res.data user', res.data);
                const user = {
                    email: res.data.email || "",
                    hub_id: res.data.hub_id || "",
                    hub_domain: res.data.hub_domain || ""
                }
                actions.login(user)
            } else {
                localStorage.removeItem('brixToken')
                actions.logout()
            }
        }
    }),
    addUser: thunk((actions, payload) => {
        actions.setUser(payload.user)
        actions.setToken(payload.token)
    }),
    getToken: thunk(async (actions, payload) => {
        if (!!payload) {
            const res = await axios.get(
                `${TOKEN_ENDPOINT}?code=${payload}`
            ).catch(e => { console.log('getToken error: ', e.message); })

            if (!!res && !!res.data && !!res.data.token) {
                localStorage.setItem('brixToken', res.data.token)
                const user = {
                    email: res.data.email || "",
                    hub_id: res.data.hub_id || "",
                    hub_domain: res.data.hub_domain || ""
                }
                actions.addUser({
                    token: res.data.token,
                    user: user
                })
            } else {
                actions.logout();
            }
        } else {
            actions.logout();
        }
    }),
}

export { user }