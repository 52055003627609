import React from 'react'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ItemAction from './ItemAction'

const ItemActionEditPage = ({ title, shortTitle, onClick, onAfterClick, variant, hasTooltip, IconComponent, item, IconButtonClassName }) => {

    if (!item || !item.editor_url) {
        return null;
    }

    const options = {
        title: title || 'Edit',
        shortTitle: shortTitle || 'Edit',
        disabled: !item.editor_url,
        IconComponent: IconComponent || EditOutlinedIcon,
        variant: variant || "IconButton",
        hasTooltip: !!hasTooltip,
        IconButtonClassName: IconButtonClassName
    }



    const handleClick = (e) => {
        if (!!onClick) {
            onClick()
        } else {
            let win = window.open(item.editor_url, '_blank');
            if (win) {
                win.focus();
            } else {
                alert('Please allow popups for this website');
            }
        }

        if (!!onAfterClick) {
            onAfterClick();
        }
    }

    return (
        <ItemAction onClick={handleClick} {...options} />
    )
}

export default ItemActionEditPage
