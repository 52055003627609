import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { AUTHORIZE_ENDPOINT } from '../../store/index.js';
import { useStoreState } from 'easy-peasy';

const AuthorizationButton = () => {
    const user = useStoreState((state) => state.user);
    const { token, isAuthenticated } = user;

    const handleClick = (e) => {
        e.preventDefault();
        window.location.href = `${AUTHORIZE_ENDPOINT}?next_url=${window.location.origin}/auth`
    }

    return !!token && !isAuthenticated ? (
        <CircularProgress />
    ) : (
            <Button
                color="primary"
                variant="contained"
                onClick={handleClick}
            >
                Create your inventory
            </Button>
        )

}

export default AuthorizationButton
