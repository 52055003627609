import React from 'react'
import { Box, makeStyles, IconButton } from '@material-ui/core'
import KeyboardTabOutlinedIcon from '@material-ui/icons/KeyboardTabOutlined';
import { useHistory } from "react-router-dom";
import BrixLogo from '../BrixLogo'
import Searchbox from '../Searchbox'
import CustomTooltip from '../CustomTooltip'
import UserButton from './UserButton'
import ListsButton from './ListsButton'
import SupportButton from './SupportButton'
import SettingsButton from './SettingsButton'
import { useStoreState, useStoreActions } from 'easy-peasy';


const useStyles = makeStyles((theme) => ({
    root: {
        height: 64,
        width: '100%',
        position: 'relative',
        zIndex: theme.zIndex.drawer + 1,
    },
    header: {
        background: '#fff',
        zIndex: 2,
        height: 64,
        width: '100%',
        position: 'fixed',
        top: '0',
        left: '0',
        borderBottom: '1px solid #D3DEEC',
        display: 'flex',
        flexWrap: 'nowrpa',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        padding: '7px 16px'
    },
    left: {
        marginRight: 20,
        "& svg": {
            cursor: 'pointer',
            width: '42px',
            maxWidth: '42px',
            height: '42px'
        }
    },
    center: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '604px'
    },
    right: {
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
        "& button": {
            "&:hover": {
                backgroundColor: '#F3FAFE'
            }
        }
    },
    toggleSidebarMobile: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    }
}))

const Header = () => {
    const classes = useStyles();
    const user = useStoreState((state) => state.user);
    const { isAuthenticated } = user;
    const application = useStoreState((state) => state.application);
    const { sidebarOpened } = application;
    const toggleSidebar = useStoreActions((actions) => actions.application.toggleSidebar);
    const selectItem = useStoreActions((actions) => actions.application.selectItem);
    let history = useHistory();


    const handleSidebarButtonClick = () => {
        toggleSidebar();
        selectItem(false)
    }


    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.left}>
                    <span onClick={() => {
                        history.push("/");
                    }}><BrixLogo /></span>
                </div>
                <div className={classes.center}>
                    {!!isAuthenticated && (
                        <Searchbox />
                    )}
                </div>
                <div className={classes.right}>
                    <ListsButton />
                    <SupportButton />
                    <SettingsButton />
                    <UserButton />

                    {!!sidebarOpened && (
                        <Box className={classes.toggleSidebarMobile}>
                            <CustomTooltip title="Close details">
                                <IconButton onClick={handleSidebarButtonClick}>
                                    <KeyboardTabOutlinedIcon />
                                </IconButton>
                            </CustomTooltip>
                        </Box>
                    )}

                </div>
            </div>
        </div>
    )
}

export default Header
