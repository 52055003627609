import { action, thunk } from 'easy-peasy';
import axios from 'axios';

const BRIX_API_URL = 'https://brix-wam-api.herokuapp.com/api/'
const LISTS_ENDPOINT = BRIX_API_URL + 'lists'


const lists = {
    lists: {},
    setLists: action((state, payload) => {
        state.lists = payload
    }),
    setList: action((state, payload) => {
        if (!!state.lists && !!state.lists.items && !!state.lists.items.find(l => l.id === payload.id)) {
            const newItems = state.lists.items.map(i => {
                if (i.id === payload.id) {
                    return payload
                } else {
                    return i
                }
            })
            state.lists.items = newItems
        } else {
            state.lists.items.push(payload)
        }
    }),
    getLists: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const res = await axios.get(
            LISTS_ENDPOINT + '?limit=30',
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => { console.log('getLists error: ', e.message); })

        if (!!res && !!res.data) {
            actions.setLists(res.data)
        }
    }),
    create: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const setSnackbar = helpers.getStoreActions().application.setSnackbar
        const list = payload
        const res = await axios.post(
            LISTS_ENDPOINT,
            {
                name: list.name,
                visibility: list.visibility,
                items: !!list.items ? list.items.map(i => (`${i.asset_type}:${i.id}`)) : []
            },
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => {
            console.log('addToList error: ', e.message);
        })
        if (!!res && !!res.data && !!res.data.id) {
            actions.setList(res.data)
            setSnackbar({
                type: 'success',
                text: `"${list.name}" successfully created`
            })
            return res.data;
        }
        return false;

    }),
    delete: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const list = payload
        const res = await axios.delete(
            LISTS_ENDPOINT + '/' + list.slug, {
            param: "1"
        },
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => {
            console.log('deleteList error: ', e.message);
        })

    }),
    createList: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const setSnackbar = helpers.getStoreActions().application.setSnackbar
        const item = payload.item
        const list = payload.list
        const res = await axios.post(
            LISTS_ENDPOINT,
            {
                name: list.name,
                items: !!item.asset_type && !!item.id ? [`${item.asset_type}:${item.id}`] : [],
                visibility: list.visiblity
            },
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => {
            console.log('addToList error: ', e.message);
        })
        if (!!res && !!res.data && !!res.data.id) {
            actions.setList(res.data)
            if (!!item.name) {
                setSnackbar({
                    type: 'success',
                    text: `"${item.name}" successfully added to "${list.name}" list`
                })
            }

        }
    }),
    addToList: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const setSnackbar = helpers.getStoreActions().application.setSnackbar
        const item = payload.item
        const list = payload.list;
        if (!item.asset_type || !item.id) {
            return;
        }
        const res = await axios.patch(
            LISTS_ENDPOINT + '/' + list.id,
            {
                items: [...list.items, `${item.asset_type}:${item.id}`]
            },
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => {
            console.log('addToList error: ', e.message);
        })
        if (!!res && !!res.data && !!res.data.id) {
            actions.setList(res.data)
            setSnackbar({
                type: 'success',
                text: `"${item.name}" successfully added to "${list.name}" list`
            })
        }
    }),
    removeFromList: thunk(async (actions, payload) => {
        if (!!payload.item) {
            actions.removeItemFromList(payload)
        }
        if (!!payload.items) {
            actions.removeItemsFromList(payload)
        }
    }),
    removeItemFromList: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const setSnackbar = helpers.getStoreActions().application.setSnackbar
        const item = payload.item
        const list = payload.list
        const res = await axios.patch(
            LISTS_ENDPOINT + '/' + list.id,
            {
                itemsToRemove: [`${item.asset_type}:${item.id}`]
            },
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => {
            console.log('removeFromList error: ', e.message);
        })
        if (!!res && !!res.data) {
            actions.setList(res.data)
            setSnackbar({
                type: 'success',
                text: `"${item.name}" successfully removed from list`
            })
            return { success: true, data: res.data }
        } else {
            return { success: false }
        }
    }),
    removeItemsFromList: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const setSnackbar = helpers.getStoreActions().application.setSnackbar
        const list = payload.list
        const items = payload.items
        const res = await axios.patch(
            LISTS_ENDPOINT + '/' + list.id,
            {
                itemsToRemove: items.map(i => (`${i.asset_type}:${i.id}`))
            },
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => {
            console.log('removeFromList error: ', e.message);
        })
        if (!!res && !!res.data) {
            actions.setList(res.data)
            setSnackbar({
                type: 'success',
                text: `Items successfully removed from list`
            })
            return { success: true, data: res.data }
        } else {
            return { success: false }
        }
    }),
    moveToList: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const setSnackbar = helpers.getStoreActions().application.setSnackbar
        const items = payload.items
        const list = payload.list
        const from = payload.from
        const res = await axios.patch(
            LISTS_ENDPOINT + '/' + list.id,
            {
                items: [...list.items.map(i => (`${i.asset_type}:${i.id}`)), ...items.map(i => (`${i.asset_type}:${i.id}`))]
            },
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => {
            console.log('addToList error: ', e.message);
        })
        if (!!res && !!res.data && !!res.data.id) {
            actions.setList(res.data)
            const res2 = await axios.patch(
                LISTS_ENDPOINT + '/' + from.id,
                {
                    itemsToRemove: items.map(i => (`${i.asset_type}:${i.id}`))
                },
                {
                    headers: { 'Authorization': `Bearer ${state.user.token}` }
                }
            ).catch(e => {
                console.log('removeFromList error: ', e.message);
            })
            if (!!res2 && !!res2.data) {
                actions.setList(res2.data)
                setSnackbar({
                    type: 'success',
                    text: `Items successfully moved to "${list.name}" list`
                })
                return { success: true, data: res.data }
            } else {
                return { success: false }
            }

        }
    }),
}

export { lists }