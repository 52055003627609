import React, { useState } from 'react'
import { Popper, Grow, Paper, ClickAwayListener, MenuList, IconButton, makeStyles } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NestedMenuItem from "material-ui-nested-menu-item";
import ItemActionViewLivePage from './ItemActionViewLivePage'
import ItemActionViewPagePreview from './ItemActionViewPagePreview'
import ItemActionViewPageSettings from './ItemActionViewPageSettings'
import ItemActionClonePage from './ItemActionClonePage'
import ItemActionEditPage from './ItemActionEditPage'
import ItemActionCopyPagePreview from './ItemActionCopyPagePreview'
import ItemActionCopyPageLive from './ItemActionCopyPageLive'
import CustomTooltip from '../CustomTooltip'
import ItemActionAddToList from './ItemActionAddToList';

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '20px',
        color: 'inherit',
    },
    iconButton: {
        padding: 8,
        backgroundColor: 'transparent',
        "&:hover": {
            backgroundColor: '#000000',
        },
        color: '#fff'
    },
    submenuItem: {
        width: 162,
        padding: '12px 16px',
        "& svg": {
            marginLeft: 'auto'
        }
    },
    submenuPaper: {
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const ItemActionsMenu = ({ item }) => {
    const classes = useStyles();
    const [menuPosition, setMenuPosition] = useState(null);
    const { live_url, preview_url, settings_url } = item;

    const handleMoreButtonClick = (event) => {
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition(event.currentTarget);
    };

    const handleMoreMenuitemClick = (event) => {
        closeMenu();
    }

    const closeMenu = () => {
        setMenuPosition(null);
    }

    const afterDropdownActionClick = () => {
        closeMenu()
    }

    return (
        <>
            <ItemActionClonePage item={item} hasTooltip IconButtonClassName={classes.iconButton} />
            <ItemActionViewPagePreview item={item} hasTooltip IconButtonClassName={classes.iconButton} />
            <ItemActionAddToList item={item} hasTooltip IconButtonClassName={classes.iconButton} />
            <CustomTooltip title="More actions">
                <IconButton
                    className={`${classes.iconButton}`}
                    onClick={handleMoreButtonClick}
                >
                    <MoreVertIcon className={classes.icon} />
                </IconButton>
            </CustomTooltip>
            <Popper
                open={!!menuPosition}
                anchorEl={menuPosition}
                role="menu"
                transition
                placement="right-start"
                className={classes.submenuPaper}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper elevation={6}>
                            <ClickAwayListener onClickAway={() => closeMenu()}>
                                <MenuList autoFocusItem={!!menuPosition} id="item-submenu" >
                                    <NestedMenuItem
                                        label="View"
                                        parentMenuOpen={!!menuPosition}
                                        onClick={handleMoreMenuitemClick}
                                        className={classes.submenuItem}
                                        disabled={!live_url && !preview_url && !settings_url}
                                    >
                                        <ItemActionViewLivePage
                                            item={item}
                                            variant="MenuItem"
                                            onAfterClick={afterDropdownActionClick}
                                        />
                                        <ItemActionViewPagePreview
                                            item={item}
                                            variant="MenuItem"
                                            onAfterClick={afterDropdownActionClick}
                                        />
                                        <ItemActionViewPageSettings
                                            item={item}
                                            variant="MenuItem"
                                            onAfterClick={afterDropdownActionClick}
                                        />
                                    </NestedMenuItem>
                                    <ItemActionClonePage
                                        item={item}
                                        variant="MenuItem"
                                        onAfterClick={afterDropdownActionClick}
                                    />
                                    <ItemActionEditPage
                                        item={item}
                                        variant="MenuItem"
                                        onAfterClick={afterDropdownActionClick}
                                    />
                                    <NestedMenuItem
                                        label="Copy"
                                        parentMenuOpen={!!menuPosition}
                                        onClick={handleMoreMenuitemClick}
                                        className={classes.submenuItem}
                                        disabled={!live_url && !preview_url}
                                    >
                                        <ItemActionCopyPageLive
                                            item={item}
                                            variant="MenuItem"
                                            onAfterClick={afterDropdownActionClick}
                                        />
                                        <ItemActionCopyPagePreview
                                            item={item}
                                            variant="MenuItem"
                                            onAfterClick={afterDropdownActionClick}
                                        />
                                    </NestedMenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )

}

export default ItemActionsMenu
