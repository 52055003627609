import { action, thunk, computed } from 'easy-peasy';
import axios from 'axios';

const BRIX_API_URL = 'https://brix-wam-api.herokuapp.com/api/'
const ASSETS_ENDPOINT = BRIX_API_URL + 'assets'
const PAGES_ENDPOINT = ASSETS_ENDPOINT + '/pages'

const getTotal = (assets, index) => !!assets && !!assets[0] && !!assets[0][index] && !!assets[0][index].total ? assets[0][index].total : 0;

const assets = {
    assets: [],
    all: computed(state => [].concat(...state.assets.map(a => [].concat(...a.map(ar => ar.items))))),
    total: computed(state => (
        {
            all: !!state.assets && state.assets.length > 0 ? state.assets[0].map(a => a.total).reduce((a, b) => a + b, 0) : 0,
            pages: getTotal(state.assets, 0),
            templates: getTotal(state.assets, 1),
            modules: getTotal(state.assets, 2),
            themes: getTotal(state.assets, 3)
        }
    )),
    initAssets: action((state, payload) => {
        state.assets = [payload]
    }),
    pushAssets: action((state, payload) => {
        state.assets.push(payload)
    }),
    unshiftAsset: action((state, payload) => {
        if (!!state.assets[0] && !!state.assets[0][0] && !!state.assets[0][0].items) {
            state.assets[0][0].items.unshift(payload)
        }
    }),
    setAssets: thunk((actions, payload, helpers) => {
        const setSearching = helpers.getStoreActions().application.setSearching
        actions.initAssets(payload)
        setSearching(false)
    }),
    addAssets: thunk((actions, payload, helpers) => {
        const setSearching = helpers.getStoreActions().application.setSearching
        actions.pushAssets(payload)
        setSearching(false)
    }),
    searchByString: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const setSearchQuery = helpers.getStoreActions().application.setSearchQuery
        setSearchQuery(payload)
        const res = await axios.get(
            `${ASSETS_ENDPOINT}?search=${payload}`,
            {
                headers: {
                    'Authorization': `Bearer ${state.user.token}`
                }
            }
        ).catch(e => { console.log('searchAssetsByString error: ', e.message); })

        if (!!res && !!res.data && !!res.data && res.data.length > 0) {
            actions.setAssets(res.data)
        } else {
            actions.setAssets([])
        }
    }),
    getAssets: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const res = await axios.get(
            `${ASSETS_ENDPOINT}?search=${state.application.searchQuery}`,
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => { console.log('getAssets error: ', e.message); })

        if (!!res && !!res.data && !!res.data && res.data.length > 0) {
            actions.setAssets(res.data)
        }
    }),
    loadMoreAssets: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const currentAssets = state.assets.assets;
        const offset = Math.max(...currentAssets[currentAssets.length - 1].map(a => a.offset))
        const query = `${ASSETS_ENDPOINT}?search=${state.application.searchQuery}&offset=${offset}&limit=20`;
        const res = await axios.get(
            query,
            {
                headers: { 'Authorization': `Bearer ${state.user.token}` }
            }
        ).catch(e => { console.log('loadMorePages error: ', e.message); })

        if (!!res && !!res.data && !!res.data && res.data.length > 0) {
            actions.addAssets(res.data)
        }
    }),
    cloneAsset: thunk(async (actions, payload, helpers) => {
        const state = helpers.getStoreState();
        const applicationActions = helpers.getStoreActions().application
        const { setSnackbar, selectItem } = applicationActions
        setSnackbar({ type: 'info', text: 'Cloning...' })
        const res = await axios.post(`${PAGES_ENDPOINT}/${payload}/clone`, {}, {
            headers: {
                'Authorization': `Bearer ${state.user.token}`
            }
        }).catch(e => {
            console.log('cloneAsset error: ', e.message);
            if (!!e.response && !!e.response.data && !!e.response.data.message) {
                setSnackbar({
                    type: 'error',
                    text: e.response.data.message
                })
            }
        })

        if (!!res && !!res.data && !!res.data.id) {
            actions.unshiftAsset(res.data)
            setSnackbar({
                type: 'success',
                text: 'Cloned!'
            })
            selectItem(res.data)
            if (!!res.data.editor_url) {
                let win = window.open(res.data.editor_url, '_blank');
                if (win) {
                    win.focus();
                }
            }
        }
    }),
}

export { assets }