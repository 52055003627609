import React from 'react'
import { MenuItem, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    submenuItem: {
        width: 162,
        padding: '12px 16px',
        "& svg": {
            marginLeft: 'auto'
        }
    },
}));

const ItemActionMenuItem = ({ shortTitle, onClick, disabled, IconComponent }) => {
    const classes = useStyles();

    return (
        <MenuItem
            className={classes.submenuItem}
            onClick={onClick}
            disabled={!!disabled}
        >
            {shortTitle}
            <IconComponent />
        </MenuItem>
    )
}

export default ItemActionMenuItem
