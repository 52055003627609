import React, { useRef } from 'react'
import ItemDetails from '../Items/ItemDetails'
import SidebarPlaceholder from './SidebarPlaceholder'
import { useStoreState, useStoreActions } from 'easy-peasy';

const Sidebar = () => {
    const selectedItem = useStoreState((state) => state.application.selectedItem);
    const selectItem = useStoreActions((actions) => actions.application.selectItem);

    const selectItemRef = useRef(selectItem)
    selectItemRef.current = selectItem

    return (
        <div>
            {!!selectedItem ? (
                <ItemDetails item={selectedItem} />
            ) :
                (<SidebarPlaceholder />)}
        </div>
    )
}

export default Sidebar
