import React from 'react'
import ItemsList from './ItemsList'
import { useParams } from 'react-router-dom'
import { useStoreState } from 'easy-peasy';

const ItemsByListSlug = () => {
    let { slug } = useParams();
    const user = useStoreState((state) => state.user.user);
    const lists = useStoreState((state) => state.lists.lists);


    const items = !!lists && !!lists.items ? [].concat.apply([], lists.items.filter(l => l.slug === slug).map(i => i.items.filter(
        i => !!i.portal_id && i.portal_id === user.hub_id
    ))) : []

    return (
        <ItemsList
            items={items}
            loadMore={() => { }}
            hasMore={false}
            total={{
                all: items.length,
                pages: items.filter(i => i.asset_type === 'page').length,
                templates: items.filter(i => i.asset_type === 'template').length,
                modules: items.filter(i => i.asset_type === 'module').length,
                themes: items.filter(i => i.asset_type === 'theme').length
            }}
            selectable
        />
    )
}

export default ItemsByListSlug