import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import ListCreateButton from './ListCreateButton'
import ListsList from './ListsList'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
    }
}))

const ListsSidebar = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Box mb="12px">
                <ListCreateButton />
            </Box>
            <ListsList />
        </div>
    )
}

export default ListsSidebar
