import React, { useState } from 'react'
import { Portal, Button } from '@material-ui/core'
import ListCreateDialog from './ListCreateDialog'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const ListCreateButton = ({ fullWidth, onClick, onClose, initialValues }) => {
    const [dialogOpened, setDialogOpened] = useState(false)

    const handleButtonClick = (event) => {
        setDialogOpened(true);
        if (!!onClick) {
            onClick();
        }
    }

    const handleDialogClose = () => {
        setDialogOpened(false);
        if (!!onClose) {
            onClose();
        }
    }

    return (
        <>
            <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleButtonClick}
                fullWidth={fullWidth}
            >New List</Button>
            <Portal>
                <ListCreateDialog initialValues={initialValues} open={dialogOpened} onClose={handleDialogClose} />
            </Portal>
        </>
    )
}

export default ListCreateButton
