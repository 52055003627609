import React from 'react'
import { List } from '@material-ui/core'
import { useStoreState } from 'easy-peasy';
import ListItem from './ListItem'

const ListsList = () => {
    const lists = useStoreState((state) => state.lists.lists.items);

    return (
        <List>
            {!!lists && lists.length > 0 && lists.map(list =>
                (<ListItem key={list.id} list={list} />)
            )}
        </List>
    )
}

export default ListsList
