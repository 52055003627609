import React, { useState } from 'react'
import { ListItemIcon, makeStyles, Dialog, DialogTitle, DialogActions, DialogContent, Button, ButtonGroup, Typography, ListItemText, Box, Popper, Grow, Paper, ClickAwayListener, MenuItem, MenuList, Input, ListItemSecondaryAction } from '@material-ui/core'
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import ItemAction from './ItemAction'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ListCreateButton from '../Lists/ListCreateButton'

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
    active: {
        color: theme.palette.primary.main,
        "& svg": {
            color: theme.palette.primary.main,
        }
    }
}))

const ItemActionAddToList = ({ title, shortTitle, onClick, onAfterClick, variant, hasTooltip, IconComponent, item, IconButtonClassName }) => {
    const listsState = useStoreState((state) => state.lists);
    const { lists } = listsState;
    const addToList = useStoreActions((actions) => actions.lists.addToList);
    const removeFromList = useStoreActions((actions) => actions.lists.removeFromList);
    const createList = useStoreActions((actions) => actions.lists.createList);

    const [menu, setMenu] = useState(false)
    const [listName, setListName] = useState("")
    const [dialogOpened, setDialogOpened] = useState(false)
    const classes = useStyles();

    const menuOpened = Boolean(menu);

    if (!item) {
        return null;
    }

    const options = {
        title: title || 'Add to list',
        shortTitle: shortTitle || 'Add to list',
        disabled: false,
        IconComponent: !!IconComponent ? IconComponent : !!lists && !!lists.items && lists.items.filter(i =>
            i.items.filter(it => it.id === item.id).length > 0
        ).length > 0 ? BookmarkOutlinedIcon : BookmarkBorderOutlinedIcon,
        variant: variant || "IconButton",
        hasTooltip: !!hasTooltip,
        IconButtonClassName: IconButtonClassName
    }

    const AddListDialog = ({ onClose, open }) => {
        const [listVisibility, setListVisibility] = useState("private")
        const classes = useStyles()

        const handleClose = () => {
            onClose();
        };

        const handleSave = () => {
            createList({ item, list: { name: listName, visibility: listVisibility } })
            onClose();
        }

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Create new list: {listName}</DialogTitle>
                <DialogContent dividers>
                    <Typography variant="subtitle1">Visibility:</Typography>
                    <ButtonGroup variant="contained" color="primary">
                        <Button
                            color={listVisibility === 'public' ? 'primary' : 'default'}
                            startIcon={<LanguageOutlinedIcon />}
                            onClick={() => {
                                setListVisibility("public")
                            }}
                        >Public</Button>
                        <Button
                            color={listVisibility === 'private' ? 'primary' : 'default'}
                            startIcon={<LockOutlinedIcon />}
                            onClick={() => {
                                setListVisibility("private")
                            }}
                        >Private</Button>
                    </ButtonGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleClick = (e) => {
        if (!!onClick) {
            onClick(e)
        } else {
            //addToList(item.id, 'test list')
            setMenu(e.currentTarget);
        }
        if (!!onAfterClick) {
            onAfterClick(e);
        }
    }

    const handleAddToListIconClick = (list) => {
        if (!!list && !!list.name && !list.items.find(i => i.id === item.id)) {
            addToList({ item, list })
        } else {
            removeFromList({ item, list })
        }
        setMenu(null);
        setListName("")
    }

    const handleAddbuttonClick = () => {
        if (!!listName) {
            setDialogOpened(true)
            //addToList(item, listName)
        }
        setMenu(null);
        //setListName("")
    }

    return (
        <>
            <ItemAction onClick={handleClick} {...options} aria-controls={menuOpened ? 'add-to-list-menu' : undefined} aria-haspopup="true" />
            <Popper open={menuOpened} anchorEl={menu} role={undefined} transition className={classes.popper}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper style={{ maxHeight: 300, minWidth: 250, overflow: 'auto', overscrollBehavior: 'none' }}>
                            <ClickAwayListener onClickAway={() => {
                                setMenu(null)
                            }}>
                                <div>
                                    <MenuList role={undefined} id="add-to-list-menu">
                                        {!!lists && !!lists.items && lists.items.length > 0 && lists.items.map((listItem, i) => (
                                            <MenuItem
                                                key={listItem.id}
                                                onClick={e => {
                                                    handleAddToListIconClick(listItem)
                                                }}
                                                className={!!listItem.items.find(i => i.id === item.id) ? classes.active : ''}
                                            >
                                                <ListItemIcon>
                                                    <BookmarkOutlinedIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>{listItem.name}</ListItemText>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                    <Box mx={2} mt={0} mb={2}>
                                        <ListCreateButton onClose={() => {
                                            setMenu(null)
                                        }} fullWidth initialValues={{ items: [item] }} />
                                    </Box>
                                </div>

                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <AddListDialog
                onClose={() => {
                    setDialogOpened(false);
                    setListName("")
                }}
                open={dialogOpened}
            />
        </>
    )
}

export default ItemActionAddToList
