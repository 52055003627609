import React, { useEffect, useRef } from 'react'
import './App.css';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Header from './components/Header'
import Layout from './components/Layout'
import Authorization from './components/Authorization'
import Welcome from './components/Welcome'
import { useStoreState, useStoreActions } from 'easy-peasy';

const App = () => {
  const user = useStoreState((state) => state.user);
  const verifyToken = useStoreActions((actions) => actions.user.verifyToken);
  const { token, isAuthenticated } = user;

  const verifyTokenRef = useRef(verifyToken);
  verifyTokenRef.current = verifyToken

  useEffect(() => {
    verifyTokenRef.current();
  }, [token, isAuthenticated])


  return (

    <div className="app">

      <Header />

      <Switch>
        <Route path="/" exact>

          {!!isAuthenticated ? (
            <Layout page="all" />
          ) : (
              <Welcome />
            )}

        </Route>


        <Route path="/lists">

          {!!isAuthenticated ? (
            <Layout />
          ) : (
              <Welcome />
            )}

        </Route>
        <Route path="/auth" exact>
          {!!isAuthenticated ? (
            <Redirect to="/" />
          ) : (
              <Authorization />
            )}

        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>


    </div>

  )
}

export default App

