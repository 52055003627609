import React, { useState, useCallback, useEffect } from 'react'
import { CircularProgress, Typography, makeStyles, InputBase } from '@material-ui/core'
import { debounce } from 'throttle-debounce';
import SearchIcon from '@material-ui/icons/Search';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import layoutIcon from '../../assets/media/svg/icon-layout.svg'
import modulesIcon from '../../assets/media/svg/icon-modules.svg'
import gridIcon from '../../assets/media/svg/icon-grid.svg'
import elementsIcon from '../../assets/media/svg/icon-elements.svg'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy';

const dropdownMenu = [
    {
        label: 'Pages',
        icon: layoutIcon
    },
    {
        label: 'Sections',
        icon: gridIcon
    },
    {
        label: 'Composites',
        icon: elementsIcon
    },
    {
        label: 'Modules',
        icon: modulesIcon
    }
]

const useStyles = makeStyles((theme) => ({
    searchbox: {
        position: 'relative',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#959CAE',
        zIndex: 2
    },
    expandIcon: {
        cursor: 'pointer',
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#959CAE',
        right: 0,
        top: 2,
        zIndex: 2
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
        position: 'relative',
        zIndex: 1
    },
    inputInput: {

        padding: '15px 56px 14px',
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        fontSize: '16px',
        lineHeight: '24px',
        borderRadius: 2,
        backgroundColor: "rgba(211,222,236,0.5)",
        border: '1px solid rgba(211,222,236,0)',
        "&:focus": {
            backgroundColor: 'rgba(255,255,255,0.5)',
            border: '1px solid #2569E8',
        },
    },
    dropdown: {
        display: 'block',
        margin: '0',
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        background: '#FFFFFF',
        border: '1px solid #D3DEEC',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)',
        padding: '0 20px',
        overflow: 'auto',
        maxHeight: 400,
        "& li": {
            cursor: 'pointer',
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'nowrap',
            padding: '12px 0',
            color: '#061942',
            overflow: 'hidden',
            "&:hover": {
                color: '#2569E8'
            },
            "& h6": {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: 'inherit'
            },
            "& img": {
                width: 20,
                minWidth: 20,
                heught: 20,
                marginRight: 16
            }
        }
    },
    searching: {
        position: 'absolute',
        right: 15,
        top: 15
    }
}));


const Searchbox = () => {
    const classes = useStyles();
    //const [dropdownOpened, setDropdownOpened] = useState(false);
    const isSearching = useStoreState((state) => state.application.isSearching);
    const searchAssetsByString = useStoreActions((actions) => actions.assets.searchByString);
    const [searchValue, setSearchValue] = useState("");
    const history = useHistory();
    const debounceSearch = useCallback(debounce(1000, searchAssetsByString), []);
    const [searching, setSearching] = useState(false)

    const doSearch = (e) => {
        if (e.target.value.length > 2) {
            setSearching(true)
            debounceSearch(e.target.value)
        }
        if (e.target.value.length < 1) {
            setSearching(true)
            debounceSearch(e.target.value)
        }
        if (!!e.target.value.length) {
            history.push('/')
        }
    }


    const handleChange = (e) => {
        setSearchValue(e.target.value);
        doSearch(e)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            doSearch(event)
        }
    }

    useEffect(() => {
        setSearching(isSearching)
    }, [isSearching])


    return (
        <>
            <div className={classes.searchbox}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    aria-controls="simple-menu" aria-haspopup="true"
                    /*onBlur={(event) => {
                        setDropdownOpened(false)
                    }}*/
                    /*onFocus={(event) => {
                        setDropdownOpened(searchValue.length < 1)
                    }}*/
                    value={searchValue}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                />
                {/*<div className={classes.expandIcon}>

                    <ExpandMoreIcon onClick={() => {
                        setDropdownOpened(!dropdownOpened)
                    }} />

                </div>*/}
                {!!searching && (
                    <CircularProgress className={classes.searching} size={20} />
                )}

                <ul className={classes.dropdown} style={{ display: 'none'/*!!dropdownOpened ? 'block' : 'none'*/ }}>
                    {dropdownMenu.map(menuItem => (
                        <li key={menuItem.label}>
                            <img src={menuItem.icon} alt={menuItem.label} />
                            <Typography variant="subtitle2">{menuItem.label}</Typography>
                        </li>
                    ))}

                </ul>

            </div>
        </>
    )
}

export default Searchbox
