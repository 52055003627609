import React from 'react'
import ItemActionViewLivePage from './ItemActionViewLivePage'
import ItemActionViewPagePreview from './ItemActionViewPagePreview'
import ItemActionViewPageSettings from './ItemActionViewPageSettings'
import ItemActionClonePage from './ItemActionClonePage'
import ItemActionEditPage from './ItemActionEditPage'
import ItemActionCopyPagePreview from './ItemActionCopyPagePreview'
import ItemActionCopyPageLive from './ItemActionCopyPageLive'
import ItemActionAddToList from './ItemActionAddToList'

const ItemActionsList = ({ item }) => (
    <>
        <ItemActionViewLivePage item={item} hasTooltip />
        <ItemActionViewPagePreview item={item} hasTooltip />
        <ItemActionViewPageSettings item={item} hasTooltip />
        <ItemActionClonePage item={item} hasTooltip />
        <ItemActionEditPage item={item} hasTooltip />
        <ItemActionCopyPageLive item={item} hasTooltip />
        <ItemActionCopyPagePreview item={item} hasTooltip />
        <ItemActionAddToList item={item} hasTooltip />
    </>
)

export default ItemActionsList
